.mainLayout {
  width: 100%;
  height: 100dvh;
  //min-height: 100vh;
  position: relative;
  display: flex;
  .menu_button {
    display: none;
  }
  .logo_bg_wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: 'hidden';
    z-index: 0;
    pointer-events: 'none';
  }
}

// @media screen and (max-width: 1200px) {
//   .mainLayout {
//     .menu_button {
//       display: block;
//       position: absolute;
//       z-index: 1000;
//     }
//     // .logo_bg_wrapper {
//     //   display: none;
//     // }
//   }
// }
