.chart_tooltip {
  background: #121344 !important;
  padding: 16px 24px;

  .value_wrapper {
    .value {
      color: #fff;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .difference {
      margin-left: 10px;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .date {
    margin-top: 12px;
    color: #7980a9;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
