.holdings_tab {
  background: linear-gradient(180deg, #090c2b 0%, #0e1240 100%);
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 8px;
  .holdings_wrapper {
    height: 100%;
    padding: 2em;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: fit-content;
    margin-bottom: 2em;
    align-items: center;
    overflow: unset;
    .name {
      color: #fff;
      font-family: Lato;
      font-size: 1.4em;
      font-weight: 500;
      line-height: 1em;
      line-height: normal;
    }
  }
}

.user_button {
  padding: 0.7em !important;
  background: rgba(10, 13, 44, 1) !important;
  border: 2px solid rgb(19, 27, 74) !important;
  font-size: 0.8em !important;
  border-radius: 8px !important;
  float: right; // transition: background 0.2s;
  text-transform: capitalize !important;
  .user_button_icon {
    transition: filter 0.2s;
    width: 0.8em;
  }
  &.active {
    background: rgba(57, 52, 195, 1) !important;
    .user_button_icon {
      filter: brightness(0) invert(1);
    }
  }
}

@media (orientation: portrait) {
  .holdings_tab {
    background: none;
    .holdings_wrapper {
      padding: 2em;
    }
    .header {
      align-items: unset;
      /* margin-bottom: 3em; */
      flex-direction: column;
      .name {
        font-size: 3.4em;
        margin-bottom: 1em;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .holdings_tab {
    background: none;
    .holdings_wrapper {
      padding: 2em 0;
    }
  }
  .user_button {
    min-height: 35px;
  }
}
