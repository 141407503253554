.profile_page_wrapper {
  position: relative;
  z-index: 5;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10em;

  .back_button {
    position: absolute;
    top: 21px;
    left: 21px;
  }

  .left_side {
    width: 31vw;

    .user_photo_name {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 50px;

      .user_photo_container {
        width: fit-content;
        background-color: rgba(53, 67, 119, 1);
        padding: 5px;
        border-radius: 50%;
        border: 1px solid rgba(56, 108, 191, 1);
        margin-bottom: 12px;
        min-width: 10em;
        min-height: 10em;
        display: flex;

        img {
          border-radius: 50%;
          margin-bottom: -4px;
          /*width: 10em; */
        }
      }

      .user_label {
        color: white;
        font-family: Lato;
        font-size: 48px;
        font-weight: 600;
        line-height: 58px;
      }
    }
  }

  .right_side {
    width: 31vw;
  }

  &.mobile {
    justify-content: center;
    padding: 12.82vw;

    .left_side {
      width: unset;

      .user_photo_name {
        .user_photo_container {
          margin-bottom: 12px;
          width: 140px;
          height: 140px;

          img {
            width: 100%;
            height: 100%;
            margin-bottom: 0;
            border-radius: 50%;
          }
        }

        .user_label {
          font-size: 32px;
          line-height: 100%;
        }
      }
    }

    .right_side {
      width: 100%;
      margin: 0;
    }
  }
}

@media (orientation: portrait) {
  .profile_page_wrapper {
    .back_button {
      position: absolute;
      top: 21px;
      left: 21px;
    }

    .left_side {
      // display: none;
    }

    .right_side {
      margin: auto;
    }
  }
}

@media screen and (max-width: 800px) {
  .profile_page_wrapper {
    padding: 0;
  }
}

@media screen and (max-width: 600px) {
  .profile_page_wrapper {
    padding: 0;
  }
}

// @media screen and (max-width: 1600px) {
//   .profile_page_wrapper {
//     position: relative;
//     z-index: 5;
//     height: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     padding: 0 100px;
//     .back_button {
//       position: absolute;
//       top: 21px;
//       left: 21px;
//     }
//     .left_side {
//       width: 604px;
//       .user_photo_name {
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         margin-bottom: 50px;
//         .user_photo_container {
//           width: fit-content;
//           background-color: rgba(53, 67, 119, 1);
//           padding: 5px;
//           border-radius: 50%;
//           border: 1px solid rgba(56, 108, 191, 1);
//           margin-bottom: 12px;
//           img {
//             border-radius: 50%;
//             margin-bottom: -4px;
//             width: 274px;
//           }
//         }
//         .user_label {
//           color: white;
//           font-family: Lato;
//           font-size: 48px;
//           font-weight: 600;
//           line-height: 58px;
//         }
//       }
//     }
//     .right_side {
//       width: 604px;
//     }
//   }
// }

// @media screen and (max-width: 1200px) {
//   .profile_page_wrapper {
//     margin: auto;
//     justify-content: center;
//     .back_button {
//       position: absolute;
//       top: 21px;
//       left: 21px;
//     }
//     .left_side {
//       display: none;
//       .user_photo_name {
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         margin-bottom: 50px;
//         .user_photo_container {
//           width: fit-content;
//           background-color: rgba(53, 67, 119, 1);
//           padding: 5px;
//           border-radius: 50%;
//           border: 1px solid rgba(56, 108, 191, 1);
//           margin-bottom: 12px;
//           img {
//             border-radius: 50%;
//             margin-bottom: -4px;
//             width: 274px;
//           }
//         }
//         .user_label {
//           color: white;
//           font-family: Lato;
//           font-size: 48px;
//           font-weight: 600;
//           line-height: 58px;
//         }
//       }
//     }
//     .right_side {
//       width: 604px;
//     }
//   }
// }