.dashboard_body {
  // z-index: 1;
  width: 100%;
  // display: flex;
  // flex-direction: column;
  padding: 2em 1em 2em 2em;
  // flex-wrap: nowrap !important;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: auto 1fr;
  // grid-template-rows: 20% 77%;
  display: grid !important;
  gap: 20px;
  .bottom_menu {
    display: none;
  }
}

@media (orientation: portrait) {
  .dashboard_body {
    padding: 5em 1.2em 5em 1.2em;
    gap: 1em;
    grid-template-rows: 16% 83%;
  }
}

@media screen and (max-width: 800px) {
  .simulation {
    grid-template-rows: 100% !important;
  }
  .dashboard_body {
    padding: 5em 3em 85px 3em;
    gap: 1em;
    grid-template-rows: 26% 73%;
    .bottom_menu {
      display: flex;
      position: absolute;
      bottom: 2em;
      padding: 0;
      margin: 0;
      width: 100%;
      align-items: center;
      justify-content: center;
      gap: 4em;
      .bottom_menu_item {
        background-color: rgba(19, 27, 74, 1);
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        width: 71px;
        height: 71px;
        align-items: center;
        justify-content: center;
        transition:
          background-color 0.2s ease,
          opacity 0.2s ease;
        opacity: 0.8;
        cursor: pointer;
        &.active {
          background-color: rgba(58, 53, 197, 0.7);
          opacity: 1;
        }
        &:hover {
          opacity: 1;
        }
        .label {
          font-family: Lato;
          font-size: 15px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: center;
          color: white;
          margin-top: 5px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .dashboard_body {
    padding: 6em 3em 70px 3em;
    gap: 1em;
    // grid-template-rows: 26% 73%;
    .bottom_menu {
      display: flex;
      position: absolute;
      bottom: 2em;
      left: 0;
      margin: 0;
      width: 100%;
      align-items: center;
      gap: unset;
      justify-content: space-between;
      padding: 0 3em;
      box-sizing: border-box;
      .bottom_menu_item {
        background-color: rgba(19, 27, 74, 1);
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        width: 53px;
        height: 53px;
        align-items: center;
        justify-content: center;
        transition:
          background-color 0.2s ease,
          opacity 0.2s ease;
        opacity: 0.8;
        cursor: pointer;
        img {
          width: 3em;
        }
        &.active {
          background-color: rgba(58, 53, 197, 0.7);
          opacity: 1;
        }
        &:hover {
          opacity: 1;
        }
        .label {
          font-family: Lato;
          font-size: 1.5em;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: center;
          color: white;
          margin-top: 0px;
        }
      }
    }
  }
}
// @media screen and (max-width: 1200px) {
//   .dashboard_body {
//     z-index: 1;
//     width: 100%;
//     display: flex;
//     flex-direction: column;
//     padding: 63px 20px 91px 20px;
//     flex-wrap: nowrap !important;
//   }
// }
