.login_form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  .logo_wrapper {
    flex-direction: row;
    display: flex;
    align-items: center;
    .typography_wrapper {
      font-weight: 700;
      margin-left: 31.8px;
      .company_name {
        line-height: 64px;
        font-size: 64px;
        font-family: 'EB Garamond', serif;
        color: white;
      }
      .corporation {
        font-weight: 700;
        line-height: 50px;
        font-size: 39px;
        font-family: 'EB Garamond', serif;
        letter-spacing: 5px;
        color: white;
      }
    }
  }
  .fields {
    max-width: 600px;
  }
  .buttons {
    max-width: 600px;
  }
}

@media screen and (max-width: 800px) {
  .login_form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    .logo_wrapper {
      flex-direction: row;
      display: flex;
      align-items: center;
      .typography_wrapper {
        font-weight: 700;
        margin-left: 31.8px;
        .company_name {
          line-height: 64px;
          font-size: 64px;
          font-family: 'EB Garamond', serif;
          color: white;
        }
        .corporation {
          font-weight: 700;
          line-height: 50px;
          font-size: 39px;
          font-family: 'EB Garamond', serif;
          letter-spacing: 5px;
          color: white;
        }
      }
    }
    .fields {
      max-width: 600px;
      width: 80vw;
    }
    .buttons {
      max-width: 600px;
      width: 80vw;
    }
  }
}

@media screen and (max-width: 600px) {
  .login_form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    .logo_wrapper {
      flex-direction: row;
      display: flex;
      align-items: center;
      .typography_wrapper {
        font-weight: 700;
        margin-left: 31.8px;
        .company_name {
          line-height: 64px;
          font-size: 64px;
          font-family: 'EB Garamond', serif;
          color: white;
        }
        .corporation {
          font-weight: 700;
          line-height: 50px;
          font-size: 39px;
          font-family: 'EB Garamond', serif;
          letter-spacing: 5px;
          color: white;
        }
      }
    }
    .fields {
      max-width: 600px;
      width: 80vw;
    }
    .buttons {
      max-width: 600px;
      width: 80vw;
    }
  }
}
