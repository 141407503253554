@import './styles/variables';

.centered {
  position: absolute;
  margin: auto;
  display: block;
  bottom: 0px;
  top: 0px;
  left: 50%;
  transform: translate(-50%, 0);
}

input:-webkit-autofill,
.MuiInputBase-root:has(> input:-webkit-autofill) {
  background-color: none;
}

.MuiDrawer-root {
  z-index: 500 !important;
}

body {
  font-size: 1vw;
}

::-webkit-scrollbar {
  width: 15px;
}
::-webkit-scrollbar-track {
  background-color: transparent !important;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(58, 53, 197, 1);
  border-radius: 10px;
  border: 6px solid transparent;
  background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
@media screen and (max-height: 625px) {
  body {
    font-size: 0.9vw;
  }
}

@media (orientation: portrait) {
  body {
    font-size: 0.9vh;
  }
}
