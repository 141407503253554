.dashboardLayout {
  display: flex;
  flex-grow: 1;
  height: 100dvh;
  // min-height: 100vh;
  position: relative;
  overflow: hidden;
  // background-image: url(/assets/images/Background.svg);

  .menu_button {
    display: none;
  }
}

@media (orientation: portrait) {
  .dashboardLayout {
    background: rgba(9, 12, 43, 1);
    background-image: none !important;
  }
}

@media screen and (max-width: 800px) {
  .dashboardLayout {
    .menu_button {
      left: 0.4em;
      color: white;
      display: block;
      position: absolute;
      z-index: 1000;
    }
  }
}

// @media screen and (max-width: 1200px) {
//   .dashboardLayout {
//     background: linear-gradient(180deg, #090c2b 0%, #0e1240 100%);
//     z-index: 10;
//     .menu_button {
//       display: block;
//       position: absolute;
//       z-index: 1000;
//       color: white;
//       .menu_icon {
//         color: white;
//         width: 24px;
//       }
//     }
//   }
// }
