.panel {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  /* grid-template-rows: 1fr 1fr 1fr 1fr; */
  /* display: flex; */
  width: 100%;
  /* gap: 10px; */
  gap: 20px;
  .card_wrapper {
    border-radius: 8px;
    // margin-right: 16px;
    // &:last-child {
    //   margin-right: 0;
    // }
  }
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: none;
    background-size: cover;
    &.active {
      opacity: 1 !important;
      // background: linear-gradient(180deg, #0A0D2C 0%, #191957 100%);

      // background: radial-gradient(
      //   85.16% 88.7% at 53.15% 15.3%,
      //   #524cf8 0%,
      //   rgba(0, 0, 0, 0) 100%
      // );

      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
      // background: linear-gradient(180deg, #FFFFFF 0%, rgba(0, 0, 0, 0) 100%);

      // background-image: url(assets/images/Background_spots.png);
      background-image: url('../../../../../public/assets/images/BoxBackgroundActive.png');
      background-size: cover;
      .card_stats {
        .stat {
          border: 1px solid rgba(58, 53, 197, 1);
        }
      }
    }
    &:hover {
      opacity: 0.85 !important;
    }

    padding: 0 16px;
    // background-image:
    //   linear-gradient(180deg, #0a0d2c 0%, #191957 100%),
    //   radial-gradient(
    //     145.16% 88.7% at 50.15% 11.3%,
    //     #524cf8 0%,
    //     rgba(0, 0, 0, 0) 100%
    //   ),
    //   /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
    //     linear-gradient(180deg, #ffffff 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 0.7;
    // background: radial-gradient(
    //     145.16% 88.7% at 50.15% 11.3%,
    //     #524cf8 0%,
    //     rgba(0, 0, 0, 0) 100%
    //   ),
    //   linear-gradient(180deg, #0a0d2c 0%, #191957 100%);
    background: linear-gradient(180deg, #0a0d2c 0%, #191957 100%);
    transition: opacity 0.2s;
    cursor: pointer;
    height: 100%;
    justify-content: space-between;
    .card_name {
      font-family: 'Lato';
      font-size: 1.4em;
      margin-top: 16px;
      font-weight: 500;
      line-height: 1em;
      color: white;
    }
    .card_value {
      // margin-top: 18px;
      // margin-bottom: 18px;
      display: flex;
      align-items: center;
      .label {
        font-family: 'Lato';
        font-size: 0.85em;
        font-weight: 500;
        line-height: 0.8em;
        color: rgba(188, 193, 222, 1);
        margin-right: 8px;
      }
      .value {
        font-family: 'Lato';
        font-size: 1.7em;
        font-weight: 500;
        line-height: 1.7em;
        color: white;
      }
    }
    .card_stats {
      display: flex;
      width: 100%;
      margin-bottom: 16px;
      .stat {
        display: flex;
        flex-direction: column;
        // gap: 0.2vw;
        justify-content: center;
        align-items: center;
        border: 1px solid rgba(49, 52, 84, 1);
        border-radius: 4px;
        padding: 10px;
        background: none;
        border: 1px solid #313454;
        transition: border 0.2s ease;
        &:last-child {
          flex-grow: 2;
        }
        &:first-child {
          flex-grow: 1;
          margin-right: 13px;
        }

        .stat_label {
          font-family: Lato;
          font-size: 0.7em;
          font-weight: 500;
          line-height: 1.2em;
          text-align: center;
          color: rgba(188, 193, 222, 1);
        }
        .stat_value {
          font-family: Lato;
          font-size: 0.7em;
          font-weight: 500;
          line-height: 1.2em;
          text-align: center;
          color: white;
        }
      }
    }
  }
}

@media (orientation: portrait) {
  .panel {
    gap: 2em;
    .card_wrapper {
      // margin-right: 16px;
      // &:last-child {
      //   margin-right: 0;
      // }
    }
    .card {
      .card_name {
        font-family: 'Lato';
      }
      .card_value {
        display: flex;
        align-items: center;
        flex-direction: column;
        .label {
          font-family: 'Lato';
          margin-right: 0px;
          font-size: 1em;
        }
        .value {
          font-family: 'Lato';
        }
      }
      .card_stats {
        flex-direction: column;
        align-items: unset;
        .stat {
          flex-direction: row;
          &:last-child {
            flex-grow: unset;
          }
          &:first-child {
            flex-grow: unset;
            margin-right: 0px;
          }

          .stat_label {
            font-size: 0.8em;
            margin-right: 0.3em;
          }
          .stat_value {
            font-family: Lato;
            font-size: 0.7em;
            font-weight: 500;
            line-height: 1.2em;
            text-align: center;
            color: white;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .panel {
    gap: 2em;
    grid-template-columns: 1fr;
    .card_wrapper {
      // margin-right: 16px;
      // &:last-child {
      //   margin-right: 0;
      // }
      display: none;
      &.active {
        display: block;
      }
    }
    .card {
      display: none;
      &.active {
        display: flex;
      }
      .card_name {
        font-family: 'Lato';
        font-size: 4em;
        margin-top: 5px;
      }
      .card_value {
        flex-direction: row;
        .label {
          font-size: 2.5em;
          margin-right: 0.4em;
        }
        .value {
          font-family: 'Lato';
          font-size: 5.4em;
          line-height: 1em;
        }
      }
      .card_stats {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 1em;
        margin-bottom: 5px;
        .stat {
          // flex-direction: row;
          flex-direction: column;
          &:last-child {
            flex-grow: 1;
          }
          &:first-child {
            flex-grow: 1;
            margin-right: 0px;
          }

          .stat_label {
            font-size: 1.8em;
            margin-right: 0.3em;
          }
          .stat_value {
            font-family: Lato;
            font-size: 1.8em;
            font-weight: 500;
            line-height: 1.2em;
            text-align: center;
            color: white;
          }
        }
      }
    }
  }
  .hidden {
    display: none;
  }
}

// @media screen and (max-width: 1600px) {
//   .panel {
//     // display: grid;
//     flex-wrap: nowrap;
//     .card_wrapper {
//       // margin-right: 16px;
//       // &:last-child {
//       //   margin-right: 0;
//       // }
//     }
//     .card {
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       &.active {
//         opacity: 1 !important;
//       }
//       &:hover {
//         opacity: 0.85 !important;
//       }

//       padding: 16px;
//       // background-image:
//       //   linear-gradient(180deg, #0a0d2c 0%, #191957 100%),
//       //   radial-gradient(
//       //     145.16% 88.7% at 50.15% 11.3%,
//       //     #524cf8 0%,
//       //     rgba(0, 0, 0, 0) 100%
//       //   ),
//       //   /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
//       //     linear-gradient(180deg, #ffffff 0%, rgba(0, 0, 0, 0) 100%);
//       opacity: 0.7;
//       background: radial-gradient(
//           145.16% 88.7% at 50.15% 11.3%,
//           #524cf8 0%,
//           rgba(0, 0, 0, 0) 100%
//         ),
//         linear-gradient(180deg, #0a0d2c 0%, #191957 100%);
//       transition: opacity 0.2s;
//       cursor: pointer;
//       .card_name {
//         font-size: 24px;
//         font-weight: 500;
//         line-height: 28px;
//       }
//       .card_value {
//         margin-top: 18px;
//         margin-bottom: 18px;
//         display: flex;
//         align-items: center;
//         .label {
//           font-family: 'Lato';
//           font-size: 16px;
//           font-weight: 500;
//           line-height: 19px;
//           color: rgba(188, 193, 222, 1);
//           margin-right: 8px;
//         }
//         .value {
//           font-family: 'Lato';
//           font-size: 30px;
//           font-weight: 500;
//           line-height: 36px;
//           color: white;
//         }
//       }
//       .card_stats {
//         flex-direction: column;
//         align-items: center;
//         .stat {
//           border-radius: 4px;
//           padding: 10px;
//           width: 150px;
//           flex-direction: row;
//           &:last-child {
//             flex-grow: 1;
//             margin-top: 8px;
//           }
//           &:first-child {
//             flex-grow: 1;
//             margin-right: 0px;
//           }

//           .stat_label {
//             font-family: Lato;
//             font-size: 12px;
//             font-weight: 500;
//             line-height: 15px;
//             text-align: center;
//             color: rgba(188, 193, 222, 1);
//           }
//           .stat_value {
//             font-size: 14px;
//             font-weight: 500;
//             line-height: 17px;
//             margin-left: 4px;
//           }
//         }
//       }
//     }
//   }
// }

// @media screen and (max-width: 1200px) {
//   .panel {
//     // display: grid;
//     display: flex;
//     width: 100%;
//     gap: 10px;
//     .card_wrapper {
//       // margin-right: 16px;
//       // &:last-child {
//       //   margin-right: 0;
//       // }
//     }
//     .card {
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       // padding: 13px;
//       &.active {
//         opacity: 1 !important;
//       }
//       &:hover {
//         opacity: 0.85 !important;
//       }

//       padding: 16px;
//       // background-image:
//       //   linear-gradient(180deg, #0a0d2c 0%, #191957 100%),
//       //   radial-gradient(
//       //     145.16% 88.7% at 50.15% 11.3%,
//       //     #524cf8 0%,
//       //     rgba(0, 0, 0, 0) 100%
//       //   ),
//       //   /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
//       //     linear-gradient(180deg, #ffffff 0%, rgba(0, 0, 0, 0) 100%);
//       opacity: 0.7;
//       background: radial-gradient(
//           145.16% 88.7% at 50.15% 11.3%,
//           #524cf8 0%,
//           rgba(0, 0, 0, 0) 100%
//         ),
//         linear-gradient(180deg, #0a0d2c 0%, #191957 100%);
//       transition: opacity 0.2s;
//       cursor: pointer;
//       justify-content: space-around;
//       .card_name {
//         font-size: 22px;
//         font-weight: 500;
//         line-height: 26px;
//       }
//       .card_value {
//         // margin-top: 18px;
//         // margin-bottom: 18px;
//         display: flex;
//         align-items: center;
//         flex-direction: column;
//         .label {
//           font-family: 'Lato';
//           font-size: 16px;
//           font-weight: 500;
//           line-height: 19px;
//           color: rgba(188, 193, 222, 1);
//           margin-right: 0px;
//         }
//         .value {
//           font-family: 'Lato';
//           font-size: 30px;
//           font-weight: 500;
//           line-height: 36px;
//           color: white;
//         }
//       }
//       .card_stats {
//         flex-direction: column;
//         align-items: center;
//         .stat {
//           border-radius: 4px;
//           padding: 10px;
//           width: 100%;
//           flex-direction: row;
//           &:last-child {
//             flex-grow: 1;
//             margin-top: 8px;
//           }
//           &:first-child {
//             flex-grow: 1;
//             margin-right: 0px;
//           }

//           .stat_label {
//             font-family: Lato;
//             font-size: 12px;
//             font-weight: 500;
//             line-height: 15px;
//             text-align: center;
//             color: rgba(188, 193, 222, 1);
//           }
//           .stat_value {
//             font-size: 14px;
//             font-weight: 500;
//             line-height: 17px;
//             margin-left: 4px;
//           }
//         }
//       }
//     }
//   }
// }
