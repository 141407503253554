.account_tab_wrapper {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  .securities_wrapper {
    // width: 100%;
    height: 100%;
    padding: 2em;
  }
  .securities {
    display: flex;
    border-radius: 8px;
    background: linear-gradient(180deg, #090c2b 0%, #0e1240 100%);
    flex-direction: column;
    .custom_select_768 {
      display: none;
    }
    .header {
      display: flex;
      width: 100%;
      margin-bottom: 40px;
      height: fit-content;
      justify-content: space-between;
      .labels {
        display: flex;
        flex-direction: column;
        .title {
          color: #fff;
          font-family: 'Lato';
          font-size: 1.4em;
          font-weight: 500;
          line-height: 1em;
          line-height: normal;
        }
        .stats {
          margin-top: 0.3em;
          .difference {
            font-family: Lato;
            font-size: 0.85em;
            font-weight: 500;
            line-height: 0.8em;
            line-height: normal;
          }
          .year {
            font-family: Lato;
            font-size: 0.85em;
            font-weight: 500;
            line-height: 0.8em;
            line-height: normal;
            color: #7980a9;
          }
        }
      }
    }
    .chart {
      width: 100%;
      height: 78%;
      // display: flex;
    }
  }

  .transactions_wrapper {
    background: linear-gradient(180deg, #090c2b 0%, #0e1240 100%);
    // margin-left: 16px;
    border-radius: 8px;
    overflow: hidden;
    .transactions {
      // display: flex;
      padding: 2em 0.9em 1em 0.9em;
      box-sizing: border-box;
      display: flex;
      height: 100%;
      flex-direction: column;
    }
  }
}

@media (orientation: portrait) {
  .account_tab_wrapper {
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: nowrap;
    width: 100%;
    .securities_wrapper {
      padding: 0em;
    }
    .securities {
      display: flex;
      // border-radius: 16px;
      background: none;
      padding: 0px;
      flex-direction: column;
      .header {
        align-items: center;
        margin-bottom: 3em;
        .labels {
          display: flex;
          flex-direction: column;
          .title {
            font-size: 3.4em;
          }
          .stats {
            display: none;
            .difference {
              // font-family: Lato;
              // font-size: 16px;
              // font-style: normal;
              // font-weight: 500;
              // line-height: normal;
            }
            .year {
              // font-family: Lato;
              // font-size: 16px;
              // font-style: normal;
              // font-weight: 500;
              // line-height: normal;
              // color: #7980a9;
            }
          }
        }
      }
      .chart {
        width: 100%;
        display: flex;
        flex: 1;
        height: 31em;
      }
    }

    .transactions_wrapper {
      background: none;
      // margin-left: 16px;
      padding: 0;
      .transactions {
        padding: 0;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .account_tab_wrapper {
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: nowrap;
    width: 100%;
    .securities_wrapper {
      padding: 0em;
    }
    .securities {
      display: flex;
      // border-radius: 16px;
      background: none;
      padding: 0px;
      flex-direction: column;
      .custom_select_768 {
        display: block;
      }
      .header {
        align-items: center;
        margin-bottom: 0;
        .custom_select_768 {
          display: block;
        }
        .labels {
          display: flex;
          flex-direction: column;
          .title {
            font-size: 3.4em;
          }
          .stats {
            display: none;
            .difference {
              // font-family: Lato;
              // font-size: 16px;
              // font-style: normal;
              // font-weight: 500;
              // line-height: normal;
            }
            .year {
              // font-family: Lato;
              // font-size: 16px;
              // font-style: normal;
              // font-weight: 500;
              // line-height: normal;
              // color: #7980a9;
            }
          }
        }
      }
      .chart {
        width: 100%;
        display: none;
        flex: 1;
        height: 50dvh;
        &.active {
          display: flex;
        }
      }
    }

    .transactions_wrapper {
      background: none;
      // margin-left: 16px;
      padding: 0;
      display: none;
      &.active {
        display: flex;
      }
      .transactions {
        padding: 0;
        width: 100%;
      }
    }
  }
}

// @media screen and (max-width: 1200px) {
//   .account_tab_wrapper {
//     display: flex !important;
//     flex-direction: column !important;
//     flex-wrap: nowrap;
//     width: 100%;
//     .securities {
//       display: flex;
//       border-radius: 16px;
//       background: none;
//       padding: 0px;
//       flex-direction: column;
//       .header {
//         display: flex;
//         width: 100%;
//         margin-bottom: 40px;
//         height: fit-content;
//         justify-content: space-between;
//         .labels {
//           display: flex;
//           flex-direction: column;
//           .title {
//             font-size: 48px;
//             font-weight: 500;
//             line-height: 57px;
//           }
//           .stats {
//             margin-top: 11px;
//             .difference {
//               font-family: Lato;
//               font-size: 16px;
//               font-style: normal;
//               font-weight: 500;
//               line-height: normal;
//             }
//             .year {
//               font-family: Lato;
//               font-size: 16px;
//               font-style: normal;
//               font-weight: 500;
//               line-height: normal;
//               color: #7980a9;
//             }
//           }
//         }
//       }
//       .chart {
//         width: 100%;
//         display: flex;
//         flex: 1;
//         height: 388px;
//         min-height: 388px;
//       }
//     }

//     .transactions_wrapper {
//       background: none;
//       // margin-left: 16px;
//       padding: 0;
//       .transactions {
//         display: flex;
//         border-radius: 16px;
//       }
//     }
//   }
// }
