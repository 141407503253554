@media screen and (max-width: 800px) {
  .logo_bg_wrapper {
    img {
      width: 150% !important;
      position: absolute !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
    }
  }
}
