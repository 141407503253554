.transactions {
  overflow-y: auto;
}
.transactions_list {
  // padding: 0 12px;
  color: #7980a9;
  .transactions_date {
    color: #fff;
    font-family: Lato;
    font-size: 0.8em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .list_item {
    padding: 15px 0px;
    .transaction_icon {
      margin-right: 0.5em;
      width: 3em;
    }

    .name_time {
      .name {
        text-transform: capitalize;
        font-family: Lato;
        font-size: 0.8em;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .time {
        font-family: Lato;
        font-size: 0.8em;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .values {
      margin-left: auto;
      .difference {
        font-family: Lato;
        font-size: 0.8em;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .value {
        font-family: Lato;
        font-size: 0.8em;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

.search_wrapper {
  padding: 0px 25px 16px 25px;
  margin-top: -10px;
}
.custom_search_adorment {
  height: 2em;
  color: white;
}
.custom_search_icon {
  width: 100%;
  color: white;
}

@media (orientation: portrait) {
  .search_wrapper {
    display: none;
  }
  .transactions_list {
    .transactions_date {
      font-size: 1em;
    }
    .list_item {
      padding: 1em 0px;
      margin-top: 1em;
      .transaction_icon {
        width: 3.2em;
        margin-right: 1em;
      }

      .name_time {
        .name {
          font-size: 1em;
          margin-bottom: 0.2em;
        }
        .time {
          font-size: 1em;
        }
      }
      .values {
        margin-left: auto;
        .difference {
          font-size: 1em;
        }
        .value {
          font-size: 1em;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .transactions_list {
    .divider {
      margin-left: 2em;
      margin-right: 2em;
    }
    .transactions_date {
      font-size: 1.5em;
    }
    .list_item {
      padding-left: 2em;
      padding-right: 2em;
      .transaction_icon {
        width: 4.2em;
        margin-right: 1.5em;
      }

      .name_time {
        .name {
          font-size: 1.5em;
          margin-bottom: 0.2em;
        }
        .time {
          font-size: 1.5em;
        }
      }
      .values {
        margin-left: auto;
        .difference {
          font-size: 1.5em;
        }
        .value {
          font-size: 1.5em;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .transactions_list {
    .divider {
      margin-left: 2em;
      margin-right: 2em;
    }
    .transactions_date {
      font-size: 1.5em;
    }
    .list_item {
      padding-left: 0;
      padding-right: 0;
      .transaction_icon {
        width: 4.2em;
        margin-right: 1.5em;
      }

      .name_time {
        .name {
          font-size: 1.5em;
          margin-bottom: 0.2em;
        }
        .time {
          font-size: 1.5em;
        }
      }
      .values {
        margin-left: auto;
        .difference {
          font-size: 1.5em;
        }
        .value {
          font-size: 1.5em;
        }
      }
    }
  }
}

// @media screen and (max-width: 1600px) {
//   .transactions_list {
//     padding: 0 12px;
//     color: #7980a9;
//     .transactions_date {
//       color: #fff;
//       font-family: Lato;
//       font-size: 14px;
//       font-style: normal;
//       font-weight: 400;
//       line-height: normal;
//       margin-bottom: 12px;
//       margin-top: 10px;
//     }
//     .list_item {
//       padding: 10px 0px;
//       .transaction_icon {
//         margin-right: 14px;
//         width: 42px;
//       }

//       .name_time {
//         .name {
//           font-family: Lato;
//           font-size: 14px;
//         }
//         .time {
//           font-family: Lato;
//           font-size: 14px;
//         }
//       }
//       .values {
//         margin-left: auto;
//         .difference {
//           font-family: Lato;
//           font-size: 14px;
//           font-style: normal;
//           font-weight: 400;
//           line-height: normal;
//         }
//         .value {
//           font-family: Lato;
//           font-size: 14px;
//           font-style: normal;
//           font-weight: 400;
//           line-height: normal;
//         }
//       }
//     }
//   }
// }

// @media screen and (max-width: 1200px) {
//   .search_wrapper {
//     display: none;
//   }
//   .transactions_list {
//     padding: 0 12px;
//     color: #7980a9;
//     .transactions_date {
//       color: #fff;
//       font-family: Lato;
//       font-size: 14px;
//       font-style: normal;
//       font-weight: 400;
//       line-height: normal;
//       margin-bottom: 12px;
//       margin-top: 10px;
//     }
//     .list_item {
//       padding: 10px 0px;
//       .transaction_icon {
//         margin-right: 14px;
//         width: 42px;
//       }

//       .name_time {
//         .name {
//           font-family: Lato;
//           font-size: 14px;
//         }
//         .time {
//           font-family: Lato;
//           font-size: 14px;
//         }
//       }
//       .values {
//         margin-left: auto;
//         .difference {
//           font-family: Lato;
//           font-size: 14px;
//           font-style: normal;
//           font-weight: 400;
//           line-height: normal;
//         }
//         .value {
//           font-family: Lato;
//           font-size: 14px;
//           font-style: normal;
//           font-weight: 400;
//           line-height: normal;
//         }
//       }
//     }
//   }
// }
