.login_page_wrapper {
  font-family: 'EB Garamond', serif;
  color: white;
  z-index: 1;
  width: 100%;
  height: 100%;

  .login_block {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;

    .login_form {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      .logo_wrapper {
        flex-direction: row;
        display: flex;
        align-items: center;
        img {
          width: 10em;
        }
        .typography_wrapper {
          font-weight: 700;
          margin-left: 1.2em;
          .company_name {
            line-height: 1em;
            font-size: 64px;
            font-family: 'EB Garamond', serif;
            color: white;
            font-size: 3.5em;
          }
          .corporation {
            font-weight: 700;
            line-height: 1em;
            font-size: 2em;
            font-family: 'EB Garamond', serif;
            letter-spacing: 0.2em;
            color: white;
          }
        }
      }
      .fields {
        width: 30vw;
      }
      .buttons {
        width: 30vw;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .login_page_wrapper {
    font-family: 'EB Garamond', serif;
    color: white;
    z-index: 1;
    width: 100%;
    height: 100%;

    .login_block {
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      height: 100%;
      background-image: url(../../../public/assets/images/Background_mobile.png);
      background-size: 100% 100%;

      .login_form {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        .logo_wrapper {
          flex-direction: row;
          display: flex;
          align-items: center;
          img {
            width: 10em;
          }
          .typography_wrapper {
            font-weight: 700;
            margin-left: 1.2em;
            .company_name {
              line-height: 1em;
              font-size: 64px;
              font-family: 'EB Garamond', serif;
              color: white;
              font-size: 3.5em;
            }
            .corporation {
              font-weight: 700;
              line-height: 1em;
              font-size: 2em;
              font-family: 'EB Garamond', serif;
              letter-spacing: 0.2em;
              color: white;
            }
          }
        }
        .fields {
          width: 80vw;
        }
        .buttons {
          width: 80vw;
        }
      }
    }
  }
}

// @media screen and (max-width: 1200px) {
//   .login_page_wrapper {
//     font-family: 'EB Garamond', serif;
//     color: white;
//     z-index: 1;
//     width: 100%;
//     height: 100%;

//     .login_block {
//       flex-direction: column;
//       justify-content: space-around;
//       align-items: center;
//       height: 100%;

//       .login_form {
//         justify-content: center;
//         .logo_wrapper {
//           margin-bottom: 100px;
//           img {
//             width: 160px;
//           }
//           .typography_wrapper {
//             font-weight: 700;
//             margin-left: 31.8px;
//             .company_name {
//               line-height: 48px;
//               font-size: 45px;
//             }
//             .corporation {
//               line-height: 35px;
//               font-size: 27px;
//               margin-top: 8px;
//             }
//           }
//         }
//         .fields {
//           width: 600px;
//           margin-bottom: 100px;
//         }
//         .buttons {
//           width: 600px;
//         }
//       }
//     }
//   }
// }
