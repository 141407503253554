.custom_select {
  height: 40px;
  color: rgba(121, 128, 169, 1) !important;
  background-color: rgba(19, 27, 74, 1);
  border-radius: 8px !important;
  .icon {
    color: white;
    margin-right: 5px;
  }
  // width: 140px;
  &.blue_variant {
    background: none;
    color: rgba(39, 110, 192, 1) !important;
  }
}
