.tab_wrapper {
  .manage_account_bttn {
    display: flex;
    color: white;
    font-size: 1.9em;
    font-family: EB Garamond;
    width: 31.46vw;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 3px solid rgba(58, 53, 197, 1);
    cursor: pointer;
    text-decoration: none;

    .icon {
      width: 1.46vw;
      padding-right: 1.25vw;
    }
  }

  &.mobile {
    .manage_account_bttn {
      font-size: 5.64vw;
      width: unset;
      justify-content: space-between;
      padding-bottom: 10px;
      border-bottom: 3px solid rgba(58, 53, 197, 1);
      cursor: pointer;
      text-decoration: none;

      .icon {
        width: 3.33vw;
        padding-right: 4.36vw;
      }
    }
  }
}
