.tab_wrapper {
  display: flex;
  flex-direction: column;
  .buttons_wrapper {
    font-family: 'EB Garamond', serif;
    gap: 1.5vw;
    display: flex;
    flex-direction: column;
    .title {
      text-align: center;
      font-size: 2.08vw;
      color: white;
      font-family: 'EB Garamond', serif;
    }

    .button_wrapper {
      display: flex;
      gap: 1.04vw;
      align-items: center;
      cursor: pointer;

      .radio_bttn {
        min-width: 1.82vw;
        height: 1.82vw;
        padding: 2px;
        border: 3px rgba(49, 52, 84, 1) solid;
        transition: border 0.2 ease;
        border-radius: 50%;
        display: flex;

        box-sizing: border-box;

        .circle {
          border-radius: 50%;
          display: flex;
          background-color: rgba(40, 161, 133, 1);
          transition: opacity ease 0.2s;
          opacity: 0;
          flex: 1;
        }
      }
      .button_card {
        align-items: center;
        width: 35.78vw;
        border-radius: 8px;
        background: linear-gradient(180deg, #0a0d2c 0%, #191957 100%);
        color: white;
        display: flex;
        flex-direction: column;
        gap: 0.9vw;
        padding: 0.8vw 1vw;
        font-size: 1.77vw;
        .button_label {
          // font-size: 1.77vw;
        }
        .value_row {
          font-size: 1.2vw;
          color: rgba(188, 193, 222, 1);
          display: flex;
          align-items: center;

          .amount {
            padding-left: 0.3vw;
            color: rgba(40, 161, 133, 1);
            font-size: 1.77vw;
          }
        }
        .manage_account_bttn {
          display: flex;
          font-family: 'EB Garamond', serif;
          font-optical-sizing: auto;
          font-weight: 500;
          font-style: italic;
          gap: 2.6vw;
          border-bottom: 2px rgba(121, 128, 169, 0.5) solid;
          width: 100%;
          padding-bottom: 5px;
          justify-content: center;
          .icon {
            width: 1.46vw;
          }
        }
      }
      &.active {
        .radio_bttn {
          border: 3px rgba(40, 161, 133, 1) solid;
        }
        .button_card {
          background-image: url('../../../../public/assets/images/BoxBackgroundActive.png');
          background-size: 100% 100%;
        }

        .circle {
          opacity: 1;
        }
      }
    }
  }

  &.mobile {
    .buttons_wrapper {
      gap: 10vw;
      .title {
        display: none;
      }

      .button_wrapper {
        gap: 10vw;

        .radio_bttn {
          min-width: 17px;
          height: 17px;
          padding: 1px;
          border: 3px rgba(49, 52, 84, 1) solid;

          .circle {
            border-radius: 50%;
          }
        }
        .button_card {
          width: unset;
          flex: 1;
          border-radius: 8px;
          gap: 4.62vw;
          padding: 4vw 12.8vw;
          font-size: 3.5vw;
          .button_label {
            font-size: 5.64vw;
          }
          .value_row {
            font-size: 3.5vw;

            .amount {
              padding-left: 1.5vw;
              font-size: 5.64vw;
            }
          }
          .manage_account_bttn {
            display: none;
          }
        }
        &.active {
          .radio_bttn {
            border: 3px rgba(40, 161, 133, 1) solid;
          }
          .button_card {
            background-image: url('../../../../public/assets/images/BoxBackgroundActive.png');
            background-size: 100% 100%;
          }

          .circle {
            opacity: 1;
          }
        }
      }
    }
  }
}
