.account_tab_wrapper {
  // display: flex;
  flex-wrap: nowrap;
  width: 100%;
  .header {
    display: flex;
    width: 100%;
    margin-bottom: 2em;
    height: fit-content;
    justify-content: space-between;
    overflow: unset;
    .custom_select_768 {
      display: none;
    }
    .title {
      color: #fff;
      font-family: 'Lato';
      font-size: 1.4em;
      font-weight: 500;
      line-height: 1em;
      line-height: normal;
    }
  }
  .securities {
    display: flex;
    border-radius: 8px;
    background: linear-gradient(180deg, #090c2b 0%, #0e1240 100%);
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    .securities_wrapper {
      // width: 100%;
      height: 100%;
      padding: 2em;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      overflow: hidden;
    }
    .body {
      width: 100%;
      // display: flex;
      flex: 1;
      overflow: hidden;
      .table1 {
        width: 291px;
        overflow: auto;
      }
      .chart {
        padding-left: 1.2em;
        // max-height: 55vh;
        // height: 63vh;
      }
    }
  }

  .rankings_wrapper {
    background: linear-gradient(180deg, #090c2b 0%, #0e1240 100%);
    // margin-left: 16px;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    padding: 2em 0.9em 1em 0.9em;
    .transactions {
      display: flex;
      border-radius: 16px;
    }
    .table2 {
      overflow: auto;
    }
  }

  .table1_1024 {
    display: none;
  }
}

@media (orientation: portrait) {
  .account_tab_wrapper {
    display: flex !important;
    flex-direction: column !important;
    .header {
      display: flex;
      width: 100%;
      margin-bottom: 3em;
      height: fit-content;
      justify-content: space-between;
      .title {
        font-size: 3.4em;
      }
    }
    .securities {
      display: flex;
      // border-radius: 16px;
      background: none;
      padding: 0px;
      flex-direction: column;
      .body {
        display: flex;
        flex-direction: column;
        .table1 {
          display: none;
        }
        .chart {
          padding: 0px;
          height: 31em;
        }
      }
    }

    .rankings_wrapper {
      background: none;
      // margin-left: 16px;
      padding: 0;
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      .header {
        display: none;
      }

      .table1_1024 {
        display: block;
        width: 177px;
      }
      .table2 {
        width: 262px;
      }
      .transactions {
        display: flex;
        border-radius: 8px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .account_tab_wrapper {
    display: flex !important;
    flex-direction: column !important;
    .header {
      display: flex;
      width: 100%;
      margin-bottom: 3em;
      height: fit-content;
      justify-content: space-between;
      .custom_select_768 {
        display: block;
      }
      .title {
        font-size: 3.4em;
      }
    }
    .securities {
      display: flex;
      // border-radius: 16px;
      background: none;
      padding: 0px;
      flex-direction: column;
      .body {
        display: flex;
        flex-direction: column;
        .table1 {
          display: none;
        }
        .chart {
          padding: 0px;
          height: 45dvh;
          display: none;
          &.active {
            display: block;
          }
        }
      }
    }

    .rankings_wrapper {
      background: none;
      // margin-left: 16px;
      padding: 0;
      display: none;
      justify-content: space-around;
      &.active {
        display: flex;
      }
      .header {
        display: none;
      }

      .table1_1024 {
        display: block;
        width: 177px;
      }
      .table2 {
        width: 262px;
      }
      .transactions {
        display: flex;
        border-radius: 8px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .account_tab_wrapper {
    display: flex !important;
    flex-direction: column !important;
    .header {
      display: flex;
      width: 100%;
      margin-bottom: 3em;
      height: fit-content;
      justify-content: space-between;
      .custom_select_768 {
        display: block;
      }
      .title {
        font-size: 3.4em;
      }
    }
    .securities {
      display: flex;
      // border-radius: 16px;
      background: none;
      padding: 0px;
      flex-direction: column;
      .body {
        display: flex;
        flex-direction: column;
        .table1 {
          display: none;
        }
        .chart {
          padding: 0px;
          height: 45dvh;
          display: none;
          &.active {
            display: block;
          }
        }
      }
    }

    .rankings_wrapper {
      background: none;
      // margin-left: 16px;
      padding: 0;
      display: none;
      justify-content: space-around;
      flex-direction: column;
      &.active {
        display: flex;
      }
      .header {
        display: none;
      }

      .table1_1024 {
        display: block;
        width: 177px;
      }
      .table2 {
        width: 262px;
      }
      .transactions {
        display: flex;
        border-radius: 8px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .account_tab_wrapper {
    display: flex !important;
    flex-direction: column !important;
    .header {
      display: flex;
      width: 100%;
      margin-bottom: 3em;
      height: fit-content;
      justify-content: space-between;
      .custom_select_768 {
        display: block;
      }
      .title {
        font-size: 3.4em;
      }
    }
    .securities {
      display: flex;
      // border-radius: 16px;
      background: none;
      padding: 0px;
      flex-direction: column;
      min-height: 50px;

      .securities_wrapper {
        padding: 2em 0;
      }
      .body {
        display: flex;
        flex-direction: column;
        .table1 {
          display: none;
        }
        .chart {
          padding: 0px;
          height: 50dvh;
          display: none;
          &.active {
            display: block;
          }
        }
      }
    }

    .rankings_wrapper {
      background: none;
      // margin-left: 16px;
      padding: 0;
      display: none;
      justify-content: space-around;
      flex-direction: column;
      &.active {
        display: flex;
      }
      .header {
        display: none;
      }

      .table1_1024 {
        display: block;
        width: 177px;
        overflow-y: auto;
        margin-bottom: 2em;
      }
      .table2 {
        width: 262px;
        overflow-y: auto;
      }
      .transactions {
        display: flex;
        border-radius: 8px;
      }
    }
  }
}

// @media screen and (max-width: 1600px) {
//   .account_tab_wrapper {
//     // display: flex;
//     flex-wrap: nowrap;
//     width: 100%;
//     .header {
//       display: flex;
//       width: 100%;
//       margin-bottom: 40px;
//       height: fit-content;
//       justify-content: space-between;
//       .title {
//         color: #fff;
//         font-family: 'Lato';
//         font-size: 26px;
//         font-style: normal;
//         font-weight: 500;
//         line-height: normal;
//       }
//     }
//     .securities {
//       display: flex;
//       border-radius: 16px;
//       background: linear-gradient(180deg, #090c2b 0%, #0e1240 100%);
//       padding: 32px;
//       flex-direction: column;
//       .body {
//         width: 100%;
//         // display: flex;
//         flex: 1;
//         .table1 {
//           width: 291px;
//         }
//         .chart {
//           padding: 0px;
//         }
//       }
//     }

//     .rankings_wrapper {
//       background: linear-gradient(180deg, #090c2b 0%, #0e1240 100%);
//       // margin-left: 16px;

//       padding: 32px 12px 24px 12px;
//       .transactions {
//         display: flex;
//         border-radius: 16px;
//       }
//     }
//   }
// }

// @media screen and (max-width: 1200px) {
//   .account_tab_wrapper {
//     display: flex !important;
//     flex-direction: column !important;
//     .header {
//       display: flex;
//       width: 100%;
//       margin-bottom: 40px;
//       height: fit-content;
//       justify-content: space-between;
//       .title {
//         font-size: 48px;
//         font-weight: 500;
//         line-height: 57px;
//       }
//     }
//     .securities {
//       display: flex;
//       border-radius: 16px;
//       background: none;
//       padding: 0px;
//       flex-direction: column;
//       .body {
//         display: flex;
//         flex-direction: column;
//         .table1 {
//           display: none;
//         }
//         .chart {
//           padding: 0px;
//           min-height: 412px;
//           height: 412px;
//         }
//       }
//     }

//     .rankings_wrapper {
//       background: none;
//       // margin-left: 16px;
//       padding: 0;
//       display: flex;
//       justify-content: space-around;
//       .header {
//         display: none;
//       }

//       .table1_1024 {
//         display: block;
//         width: 177px;
//       }
//       .table2 {
//         width: 262px;
//       }
//       .transactions {
//         display: flex;
//         border-radius: 16px;
//       }
//     }
//   }
// }
